<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import { fly } from "svelte/transition";
	import { Product } from "./interfaces";
	import ProductCard from "./ProductCard.svelte";

	export let cart: Product[];

	const dispatch = createEventDispatcher();

	function dispatchCheckout() {
		dispatch("checkout");
	}

	function removeFromCart(i: int) {
		cart.splice(i, 1);
		cart = cart;
	}
</script>

<div
	class="position-fixed bottom-0 bg-light shadow-lg border-top p-0"
	id="cart"
	transition:fly={{ y: 50 }}
>
	{#if cart.length === 0}
		<div class="w-100 h-100 d-flex align-items-center">
			<h1 class="w-100 text-muted" id="cart-empty">Your cart is empty.</h1>
		</div>
	{:else}
		<div class="d-flex p-3" id="product-row">
			{#each cart as product, i}
				<ProductCard
					{product}
					index={i}
					on:productClicked={() => removeFromCart(i)}
				/>
			{/each}

			<button
				type="button"
				id="checkout-button"
				class="btn btn-primary"
				on:click={dispatchCheckout}
				on:keydown={dispatchCheckout}
			>
				Checkout
			</button>
		</div>
	{/if}
</div>

<style>
	#cart {
		height: 11em;
		width: 100vw;
	}

	#cart h1 {
		text-align: center;
	}

	#product-row {
		overflow-x: scroll;
		overflow-y: hidden;
		height: 100%;

		align-items: center;
		justify-content: center;

		gap: 0.5em;
	}

	#checkout-button {
		position: absolute;
		bottom: 25px;
		right: 35px;
	}
</style>

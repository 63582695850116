<script>
	import { createEventDispatcher } from "svelte";

	import { fly } from "svelte/transition";

	let userEmail = "";

	const dispatch = createEventDispatcher();

	function dispatchLoginAttempt() {
		dispatch("loginAttempt", { userEmail });
	}
</script>

<div class="w-100 h-100 bg-dark d-flex justify-content-center" id="login">
	<div
		class="w-75 d-flex flex-column justify-content-center align-items-center"
	>
		<img class="col w-100 d-inline-block" src="swiped.svg" alt="Logo" />
		<div class="col">
			<form
				class="row row-cols-lg-auto g-3 align-items-center"
				on:submit|preventDefault={dispatchLoginAttempt}
				in:fly={{ y: 50 }}
			>
				<div class="col">
					<input
						bind:value={userEmail}
						autofocus
						class="form-control form-control-lg"
						id="usr-input"
						placeholder="Username"
						type="text"
						autocomplete="off"
					/>
				</div>
				<div class="col">
					<button class="btn btn-lg btn-danger" type="submit"> Login </button>
				</div>
			</form>
		</div>
	</div>
</div>

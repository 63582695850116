import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

// Initialize the Sentry SDK here
Sentry.init({
	dsn: "https://c37f9f0d76ba4b2b8987ea6e4965f979@o4504877874085888.ingest.sentry.io/4504877902397440",
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

import App from "./App.svelte";

const app = new App({
	target: document.body,
	props: {},
});

export default app;

<script>
	import { createEventDispatcher } from "svelte";

	export let member;

	const dispatch = createEventDispatcher();

	function dispatchCartClicked() {
		dispatch("cartClicked");
	}

	function dispatchLogout() {
		dispatch("logout");
	}
</script>

<div
	class="d-flex flex-column shadow flex-shrink-0 bg-dark text-white"
	id="sidebar"
>
	<div
		class="border-bottom text-white d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none"
		id="profile-picture"
		aria-expanded="false"
	>
		{member.name}
	</div>

	<div
		role="button"
		class="border-bottom text-white d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none"
		id="profile-picture"
		aria-expanded="false"
		on:click={dispatchLogout}
		on:keydown={dispatchLogout}
	>
		Log out
	</div>

	<div
		role="button"
		class="p-4 text-white"
		on:click={dispatchCartClicked}
		on:keydown={dispatchCartClicked}
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			class="bi bi-cart"
			viewBox="0 0 16 16"
		>
			<path
				d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
			/>
		</svg>
	</div>
</div>

<style>
	#sidebar {
		width: 10vw;
		text-decoration: none;
		text-align: center;
	}

	svg {
		width: 3vw;
	}
</style>

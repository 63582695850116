<script>
	import Sidebar from "./Sidebar.svelte";
	import ProductGrid from "./ProductGrid.svelte";
	import Cart from "./Cart.svelte";
	import Login from "./Login.svelte";

	import {
		addCartToInvoice,
		fetchAllProducts,
		searchMember,
	} from "./service.ts";

	let productsPromise = fetchAllProducts();

	let buyingLock = false;

	//productsPromise.then(pds => console.log(pds)); for debug

	let cart = [];
	let cartEnabled = false;
	let member = null;
	let loadingUI = false;

	function toggleCart() {
		cartEnabled = !cartEnabled;

		if (!cartEnabled) {
			cart = [];
		}
	}

	function addToCart(event) {
		const product = event.detail.product;

		// Stolen & adapted from
		// https://svelte.dev/repl/f894a09732d74059b4820324dae003c1?version=3.32.3
		if (cartEnabled) {
			for (let item of cart) {
				if (item.id === product.id) {
					item.quantity += 1;
					cart = cart;
					return;
				}
			}
			product.quantity = 1;
			cart = [...cart, product];
		} else {
			product.quantity = 1;
			checkout([product]);
		}
	}

	function removeFromCart(event) {
		const product = event.detail.product;

		// Stolen from
		// https://svelte.dev/repl/f894a09732d74059b4820324dae003c1?version=3.32.3
		for (let item of cart) {
			if (item.id === product.id) {
				if (product.quantity > 1) {
					product.quantity -= 1;
					cart = cart;
				} else {
					cart = cart.filter((cartItem) => cartItem !== product);
				}
				return;
			}
		}
	}

	function checkout(products) {
		if (!buyingLock) {
			buyingLock = true;
			loadingUI = true;
			addCartToInvoice(member, products).then(reset);
			loadingUI = false;
		} else {
			console.log("Already placing an order...");
		}
	}

	async function handleLogin(event) {
		try {
			loadingUI = true;
			let memberCandidate = await searchMember(event.detail.userEmail);
			loadingUI = false;

			if (memberCandidate?.detail !== "Not Found") {
				member = memberCandidate;
			} else {
				alert("Member not found");
			}
		} catch (e) {
			handleLoginFail(e);
		}
	}

	function handleLoginFail() {
		alert("Login failed");
		// TODO: Add some better prompt/animation
	}

	function logout() {
		reset();
	}

	function reset() {
		// Remove member reference
		member = null;

		// Set all quantities back to 0 and empty cart
		for (let product of cart) {
			product.quantity = 0;
		}
		cart = [];
		cartEnabled = false;

		buyingLock = false;
		console.log("Reset");
	}
</script>

<main>
	{#if loadingUI}
		<div id="loading-bg">
			<div class="spinner-border text-light" role="status" />
		</div>
	{/if}
	{#if !member}
		<Login on:loginAttempt={handleLogin} />
	{:else}
		<Sidebar
			{member}
			{cartEnabled}
			on:cartClicked={toggleCart}
			on:logout={logout}
		/>
		{#await productsPromise}
			<div class="d-flex w-100 h-100 justify-content-center align-items-center">
				<div
					id="product-spinner"
					class="spinner-border text-danger"
					role="status"
				>
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
		{:then products}
			<ProductGrid {products} on:productClicked={addToCart} />
		{/await}
		{#if cartEnabled}
			<Cart
				{cart}
				on:productClicked={removeFromCart}
				on:checkout={() => checkout(cart)}
			/>
		{/if}
	{/if}
</main>

<style>
	main {
		display: flex;
		flex-wrap: nowrap;
		height: 100vh;
		max-height: 100vh;
		overflow-x: auto;
		overflow-y: hidden;
	}

	#product-spinner {
		width: 10rem;
		height: 10rem;
	}

	#loading-bg {
		height: 100vh;
		width: 100vw;
		padding: 0;
		margin: 0;
		position: absolute;
		z-index: 100;
		background: black;
		opacity: 50%;
	}

	#loading-bg > div {
		width: 20vw;
		height: 20vw;
		margin-left: 40vw;
		margin-top: calc(50vh - 10vw);
	}
</style>

import { Folder, Invoice, Member, Product } from "./interfaces";

const endpoint = "https://scapiens.scintilla.utwente.nl/swiped";
const swiped_folder_id = "4108";

export async function fetchAllProducts() {
	const folders = await fetchProductFolders();
	return await Promise.all(
		folders.map((folder: Folder) => fetchProducts(folder.id))
	);
}

export async function fetchProducts(folderId: number) {
	const response = await fetch(
		`${endpoint}/product-folders/${folderId}/products`
	);
	return await response.json();
}

export async function fetchProductFolders(): Promise<Folder[]> {
	const response = await fetch(
		`${endpoint}/product-folders/${swiped_folder_id}/subfolders`
	);
	return await response.json();
}

export async function searchMember(email: string): Promise<Member> {
	let params = new URLSearchParams({ term: email });
	let response = await fetch(`${endpoint}/members/search?${params}`);
	return await response.json();
}

function getInvoiceReference(member: Member) {
	const date = new Date();
	const month = `${date.getMonth() + 1}`.padStart(2, "0");
	return `SWIPED2-${date.getFullYear()}${month}-${member.id}`;
}

async function createInvoice(member: Member): Promise<Invoice> {
	const value = await fetch(`${endpoint}/sale-invoices`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			member_id: member.id,
			invoice_reference: getInvoiceReference(member),
			items: [],
		}),
	});

	return await value.json();
}

async function retrieveInvoice(member: Member): Promise<Invoice> {
	const params = new URLSearchParams({
		member_id: member.id.toString(),
		invoice_status: "concept",
	});

	const value = await fetch(`${endpoint}/sale-invoices?${params}`);
	const invoices = (await value.json()).data;

	for (let invoice of invoices) {
		if (invoice.invoice_reference === getInvoiceReference(member)) {
			return invoice;
		}
	}

	return await createInvoice(member);
}

// TODO: Rename this function
async function createSaleInvoiceItem(
	invoice: Invoice,
	product: Product
): Promise<void> {
	let quantity = product.quantity ?? 1;
	for (let item of invoice.items) {
		// Find quantity of product already in invoice
		if (item.product_offer_id == product.product_offer_id) {
			quantity = item.quantity + quantity;
			return updateSaleInvoiceItem(invoice, product, quantity);
		}
	}

	// It's a new product in invoice... add it:
	await addSaleInvoiceItem(invoice, product, quantity);
}

async function updateSaleInvoiceItem(
	invoice: Invoice,
	product: Product,
	quantity: number
): Promise<void> {
	let items = invoice.items;

	for (let item of items) {
		if (item.product_offer_id == product.product_offer_id) {
			item.quantity = quantity;
			break;
		}
	}

	// PUT existing item to invoice
	await fetch(`${endpoint}/sale-invoices/${invoice.id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			items: items,
			member_id: invoice.member_id,
		}),
	});
}

async function addSaleInvoiceItem(
	invoice: Invoice,
	product: Product,
	quantity: number
): Promise<void> {
	// POST new item to invoice
	await fetch(`${endpoint}/sale-invoices/${invoice.id}/items`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			product_offer_id: product.product_offer_id,
			quantity,
		}),
	});
}

export async function addCartToInvoice(member: Member, cart: Product[]) {
	const invoice = await retrieveInvoice(member);

	for (let product of cart) {
		await createSaleInvoiceItem(invoice, product);
	}
}

<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import { fly } from "svelte/transition";
	import { Product } from "./interfaces";

	export let product: Product | undefined = undefined;
	export let index: number;

	const dispatch = createEventDispatcher();

	function dispatchProductClicked() {
		dispatch("productClicked", { product });
	}

	let image = "";

	onMount(() => {
		if (product.media.length > 0) {
			image = product.media[0].url_sm;
		}
	});
</script>

<div
	class="product-card shadow card"
	on:click={dispatchProductClicked}
	on:keydown={dispatchProductClicked}
	in:fly={{ y: 50, delay: 20 * index }}
>
	<img src={image || "placeholder.png"} class="card-img-top" alt="" />
	<div class="card-spacer" />
	<div class="name">{product.name}</div>
	<div class="price w-100">€{product.price.toFixed(2)}</div>

	{#if product.quantity > 1}
		<h2 class="position-absolute badge-amount">
			<span class="badge badge-danger badge-pill bg-danger">
				{product.quantity}
			</span>
		</h2>
	{/if}
</div>

<style>
	.price {
		text-align: center;
		font-size: 1em;
		align-self: flex-end;
	}

	.product-card .name {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: center;
		margin-top: 0.5em;
	}

	.product-card {
		height: 10em;
		cursor: pointer;
		padding: 0.5em;
	}

	.product-card .card-spacer {
		flex: 1;
	}

	.product-card .card-img-top {
		height: 5em;
		width: auto;
		max-width: 5em;
		margin: 0 auto;
		object-fit: contain;
	}

	.badge-amount {
		height: 2em;
		width: 2em;
		bottom: -1em;
		right: -1em;
	}
</style>

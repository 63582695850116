<script lang="ts">
	import ProductCard from "./ProductCard.svelte";
	import { Folder, Product } from "./interfaces";

	export let products: Folder[];
	let pcount = 0;
</script>

<div class="p-3 w-100" id="products">
	{#each products as category, i}
		<h1 class="category-name">{category.name}</h1>
		<div class="gap-3 product-grid">
			{#each category.products as product, j}
				<ProductCard {product} index={i + j} on:productClicked />
			{/each}
		</div>
	{/each}
</div>

<style>
	.category-name {
		padding-top: 1em;
		padding-bottom: 1em;
		font-size: 3em;
	}

	#products {
		overflow-y: scroll;
		padding-bottom: 13em;
	}

	.product-grid {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}
</style>
